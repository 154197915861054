import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १२ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १२ वा' num='12'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे अरूपा निराकार । भक्तांसाठी होतो साकार । जागे करतो वारंवार ॥ १ ॥
आम्ही निद्रेत असणार । तू जागृत करणार । तुला कोण विसरणार ? । विसरणारा निद्रेत रहाणार ॥ २ ॥
आनंदाचा ठसा मनावर । हेच जीव मागणार । जो जागृत असणार । तोच आनंदित रहाणार ॥ ३ ।
तूच आनंद देतोस आम्हांस । जो जे काही देतो दुसर्‍यास । कोण विसरणार त्यास ? । हे कळते गणामहाराजास ॥ ४ ॥
जेणे तुला आळवतो । तूच सदा प्रसन्न होतो । तू जे जे सांगतो । ते ते भक्तां सांगतो ॥ ५ ॥
माझी तुला एक आर्जव । भक्तांसाठी घ्यावी धाव । संतोषेल भक्तांचा जीव । आनंदेल माझा जीव ॥ ६ ॥
नुसते दृष्टीने बघण्यास । नको शिकवू आम्हांस । अंतर्मनाने जाणण्यास । शिकव तू आम्हांस ॥ ७ ॥
जे दृष्टी न बघणार । ते तू बघणार । हे मी जाणणार । कैसा तुला विसरणार ? ॥ ८ ॥
तू यावे ह्या स्थाना । माझी वृत्ती स्थीर करण्या । उपयुक्त ग्रंथ लेखना । हीच मनोमन प्रार्थना ॥ ९ ॥
जैसी मी भाकतो करूणा । माझा हेतू पूर्ण करण्या । तैसे भक्त समर्थांना । भाकत होते करूणा ॥ १० ॥
अकोल्याचा बच्चुलाल । संस्काराने सुशील । पावित्र्याची वाटचाल । गुरू पूजनाची तळमळ ॥ ११ ॥ 
बच्चुलाले केली विनवणी । समर्थ सद्गुरूंनी । यावे माझ्या सदनी । गुरूपूजन हेतू मनी ॥ १२ ॥
समर्थे हेतू जाणून । स्विकारले आमंत्रण । त्यांस होकार देऊन । दिला त्यास परतवून ॥ १३ ॥
महाराज गेले अकोल्याला । बच्चुलालच्या सदनाला । आनंद झाला मनाला । हेतू पूर्ण झाला ॥ १४ ॥
पूजाविधी सुरू झाले । समर्थांस उटणे लावले । उष्णोदक स्नान घातले । जरीचा पितांबर नेसवले ॥ १५ ॥
शालीचेही प्रयोजन केले । फुलहार गळ्यात घातले । केशरी चंदन भाळी लावले । नाना अलंकार घातले ॥ १६ ॥
दहा बोटात दहा अंगठ्या । रत्नजडीतास नव्हता तोटा । वामकरी पौचा घालता । शोभून दिसे सद्गुरूनाथा ॥ १७ ॥
अष्टगंध अर्गजा अत्तर । लावले होते बाहुंवर । गुलाबपाणी अंगावर । घमघमाट सुगंध फार ॥ १८ ॥
जिलबी राघवदास पेढे । प्रसादात पुढे ठेवले । तबकात ठेवले विडे । रंगावलीने शोभा वाढे ॥ १९ ॥
वैभवाचे प्रदर्शन केले । सारे विपरित झाले । महाराजांस न रुचले । भाव चेहेर्‍यावर उमटले ॥ २० ॥
ज्याला जे आवडते । ते त्यास देण्याते । त्याचे मन संतुष्ट होते । कृपाशिष मिळते ॥ २१ ॥
दागदागिने भिरकावले । कृतीतून बोलके झाले । बच्चुलाला जागृत केले । वैभवास न भुलले ॥ २२ ॥
योग्याचे अंतरंग जाणावे । फाजील प्रदर्शन नसावे । ऐसे त्यास अर्पण करावे । जेणे त्याने संतुष्ट व्हावे ॥ २३ ॥
प्रसंग विपरित घडला । अपेक्षाभंग झाला । बच्चुलालास पस्तावा झाला । चूक स्वतःची जाणता झाला ॥ २४ ॥
महाराज वदले त्यास । मला काय पोळ्याचा बैल समजतोस ? । नाना अलंकार घालतोस । माझी परिक्षा पाहतोस ? ॥ २५ ॥
मी न बैल पोळ्याचा । अथवा घोडा दसर्‍याचा । काही न उपयोग दागदागिन्यांचा । योगी भुकेला सेवेचा ॥ २६ ॥
बच्चुलाल नतमस्तक झाला । विनवणी करू लागला । चूक कळली मला । पुन्हा न होणार प्रसंगाला ॥ २७ ॥
गुरू मला माफ करा ।  माझ्यावर कृपा करा । शुद्ध भाव अंतरा । झिडकारू नका अलंकारा ॥ २८ ॥
राममंदिर बांधण्याची । ईच्छा माझ्या मनाची । आहे कित्येक दिवसांची । ती पूर्ण करा साची ॥ २९ ॥
भक्तीरसाने ओथंबलेले । बच्चुलालाचे हृदय जाणले । समर्थांचे हृदय द्रवले । त्यास आशिर्वाद दिले ॥ ३० ॥
त्याचे पुढचे संकट टळले । महाराज कृपावंत झाले । अकोल्याहुन निघाले । शेगावास परतले ॥ ३१ ॥
पितांबर न सोडे समर्थांस । जैसे तान्हुले मातेस । सदा तत्पर सेवेस । काही न उणीवेस ॥ ३२ ॥
सदा त्यांच्या सान्निध्यात । सदा त्यांच्या पुढ्यात । गुरू वेडा अंतरंगात । दिसत होते त्यांच्या कृतीत ॥ ३३ ॥
विचार प्रत्येक मातेचे । एकच असतात तिचे । सदा हित तान्हुल्याचे । हेच लक्ष्य मातेचे ॥ ३४ ॥
विचार करावे मातेने । मोठे व्हावे तान्हुल्याने । नुसते न कधी देहाने । परी परिपूर्ण ज्ञानाने ॥ ३५ ॥
काही कारणास्तव दूर लोटते । परी चित्त बाळातच असते । जवळ राहून जे मिळते । अधिक दूर गेल्याने मिळते ॥ ३६ ॥
अहो सामान्य माता जाणते । कैसे न कळे समर्थां ते ? । शिष्याचे हित पहाण्याते । गुरूंचे मन धडपडते ॥ ३७ ॥
समर्थ ज्ञानी असल्यामुळे । वेळप्रसंगी त्यांस कळे । त्यांच्या योगलीलेमुळे । समर्थपण प्रसंगी कळे ॥ ३८ ॥
वेळ प्रसंगी लाडक्यांस । दूर लोटावे लागे त्यांस । ज्ञानाच्या शिदोरीस । सोबत होते शिष्यांस ॥ ३९ ॥
जवळ असलेल्या शिदोरीस । मातेच्या संस्कारांस । भरलेल्या ज्ञान घड्यास । फार महत्व वेळप्रसंगास ॥ ४० ॥
जैसे मायलेकरांचे असते । तैसेच गुरूशिष्यांचे असते । वात्सल्यास फार महत्व असते । जग वात्सल्यावर टिकते ॥ ४१ ॥
ऐसेच गजानन पितांबराचे । नाते होते गुरूशिष्यांचे । एकमेकांच्या ओढीचे । अतोनात वात्सल्याचे ॥ ४२ ॥
एक दिवस ऐसे जाहले । पितांबराचे मन गुंतलेले । पूजा करण्यात रमलेले । त्यातच एकरूप झालेले ॥ ४३ ॥
जे जवळ असायचे । त्यावर संतुष्ट असायचे । ऐसे वागणे पितांबराचे । गजाननाच्या शिष्याचे ॥ ४४ ॥
समर्थ वदले पितांबरास । काय नेसतोस फाटक्या वस्त्रांस ? । जुन्या पुराण्या सोळ्यास । दुसरे नको तुला नेसण्यास ? ॥ ४५ ॥
पितांबरा ! हा घे दुपेटा । हो येथून चालता । राहू नकोस येथे आता । लावू नकोस बट्टा ॥ ४६ ॥
पितांबरास वाईट वाटले । त्याला अगदी भरून आले । एका डोळ्यात हसू आले । दुसर्‍या डोळ्यात आसू आले ॥ ४७ ॥
तो दुपेटा नेसला । देहाने मनाने फुलला । क्षणभर बघत राहिला । काहि न कळे त्याला ॥ ४८ ॥
काहिंना बघवेना । ते वदले त्या क्षणा । अरे ! समर्थे दुपेटा देताना । केली कान‌उघाडण्या ॥ ४९ ॥
ही न कृपादृष्टी । ही तुझी हकालपट्टी । समर्थ अंतरंग जाणती । तेणे घडली ऐशी कृती ॥ ५० ॥
योग्याचा अंतर्भाव योगी जाणतो । दुसरा न जाणतो । हा बोल खरा ठरतो । पितांबर त्याचे उदाहरण ठरतो ॥ ५१ ॥
समर्थे हाकलवले पितांबरास । चालला पुढील रस्त्यास । निघाला पुढील कार्यास । महाराजांचे नाव करायास ॥ ५२ ॥
पाऊल पुढे पडत होते । चित्त मागे गुरूंकडे होते । महाराजांत गुंतले होते । मनाचे विचार जात नव्हते ॥ ५३ ॥
शेगाव डोळ्यासमोरून । हलत नव्हते तेथून । देहाच्या क्रिया भिन्न । मनाच्या क्रिया भिन्न ॥ ५४ ॥
मुलगी जशी सासरी जाते । तैसे त्याचे झाले होते । गुरूचे घर माहेर होते । पुढची वाट सासर होते ॥ ५५ ॥
नशिबातला सासुरवास । जो असतो प्रत्येक मुलीस । तैसा वाटत होता त्यास । दिसत होता डोळ्यांस ॥ ५६ ॥
एक आनंद मावळत होता । दुसरा उषःकालात होता । संमिश्रभाव दाटला होता । हृदय बोलके करत होता ॥ ५७ ॥
नयनी आंसवे दाटलेली । पुढची वाट धरलेली । कधीही न पाहिलेली । अगदी नवीच असलेली ॥ ५८ ॥
श्री पासून सुरू करायचे । सर्वांचेच मन राखायचे । महाराजांशिवाय जगायचे । आईशिवाय जगायचे ॥ ५९ ॥
माऊली आठवण काढताच । नाही भेटणार आत्ताच । आठवणी काढायच्या नुसत्याच । ताज्या मानायच्या त्याच ॥ ६० ॥
अशी वाट चालायची । सवय नव्हती कधी त्याची । सुखदुःखाच्या वाटेची । आईशिवाय अनुभवायची ॥ ६१ ॥
पाऊले पुढे पडत होती । जणू टाकली जात होती । अंगात शक्ती नव्हती । बोलण्याची सोय नव्हती ॥ ६२ ॥
गतकालच्या आठवणी । त्यांची होत होती उजळणी । व्यत्यय न आणला कुणी । मागच्यास पुढे टाकूनी ॥ ६३ ॥
पहिले गेल्यावाचून । दुसरे न येणार आपणहून । हेच आले घडून । पितांबर न सुटला त्यातून ॥ ६४ ॥
मागील आठवणींचे पाढे । भराभर  येती पुढे । पुढच्या जीवनाचे पाढे । घोकता घोकता जाई पुढे ॥ ६५ ॥
विचारांच्या तंद्रीत अंतर कापले । त्याचे त्याला न कळले । पहाता पहाता गाव आले । कोंडोली ग्राम दिसू लागले ॥ ६६ ॥
प्रत्येक गावचे वातावरण । अगदी असते भिन्न । तत्वाचे ठराविकपण । कधी न होणार भिन्न ॥ ६७ ॥
काही विशेष दिसताक्षणी । जमावे चारचौघांनी । मनसोक्त करावी बोलणी । वाटे प्रत्येकजण शहाणी ॥ ६८ ॥
परी वेळ प्रसंगात । शहाणे मूर्ख ठरतात । मूर्ख शहाणे ठरतात । उलटे सुलटे अनुभवतात ॥ ६९ ॥
पितांबराचे असेच झाले । रात्रभर झाडावर बसावे लागले । पहाता पहाता तांबडे फुटले । कोंबड्याचे आरवणे सुरू झाले ॥ ७० ॥
गाव जागे झाले । व्यवहार सुरू झाले । पायवाटा हालचालींमुळे । सुन्नतेचे नाव विरले ॥ ७१ ॥
पक्षांचे चिवचिवणे । गायी गुरांचे हंबरणे । पोरांचे बागडणे । होत होते मुक्तपणे ॥ ७२ ॥
आंब्याच्या झाडावर । बसलेला पितांबर । पोरे तेथे जमल्यावर । हसू लागली त्यावर ॥ ७३ ॥
तो एक एक फांदी बदलत । जात होता पुढे सरकत । माकडचेष्टा होती वाटत । पोरांना कुतुहल त्यात ॥ ७४ ॥
मुंग्या मुंगळे झाडावरी । त्यास सतावे भारी । त्यातुनी सुटका होण्यावरी । ऐसे कृत्य तो आचरी ॥ ७५ ॥
दोन जमले की चार जमतात । नियम तंतोतंत पाळतात । नेमके प्रश्र्न विचारले जातात । नेमकी उत्तरे मिळतात ॥ ७६ ॥
नियम तेथे खरा ठरला । जन समुदाय गोळा झाला । जो तो कारण पुसु लागला । त्याच्या ऐशा कृत्याला ॥ ७७ ॥
पितांबरास विचारले । त्याने सर्वांस सांगितले । जनांस कुतुहल वाटले । सार्‍यांनाच न ते पटले ॥ ७८ ॥
पट्टशिष्य गजाननाचा । बोल ऐकता पितांबराचा । गावकर्‍यांस न पटला त्याचा । म्हणती डाव भोंदूगिरीचा ॥ ७९ ॥
शिष्य ओळखला जातो । तो न सांगावा लागतो । सारा भार कृतीवर असतो । कृतीनेच नराचा नारायण होतो ॥ ८० ॥
जमलेले म्हणती त्यास । खोटे बोलू नकोस । भोंदूगिरी करू नकोस । बाया पोरांना फसवू नकोस ॥ ८१ ॥
खरे खोटे शोधून काढू । खोटे ठरता बडवून काढू । अंगाची सालटे काढू । नको बाया बापड्या भोंदू ॥ ८२ ॥
चोर ढोंगी टवाळखोर । तू बोलशील बडविल्यावर । सर्व निमूटपणे सांगितल्यावर । तुला न त्रास देणार ॥ ८३ ॥
उगाच सोंग करू नकोस । भलते सलते सांगू नकोस । आम्ही ओळखतो गजाननास । लावू नकोस बट्टा त्यास ॥ ८४ ॥
महाराजांनी वठलेल्या आंब्याला । फळे आणली त्याला । नुसती पालवी आण त्याला । दाखव तुझ्या चमत्कृतीला ॥ ८५ ॥
अनपेक्षितपणे प्रसंग आला । पितांबर अस्वस्थ झाला । गुरूंचा धावा करू लागला । मनोमन आळवू लागला ॥ ८६ ॥
म्हणाला महाराज । कैसा प्रसंग आला आज । तुम्हीच राखा माझी लाज । अन्यथा बेअब्रु होईल आज ॥ ८७ ॥
भास्करा वैकुंठा पाठविले । मी ऐसे काय केले ? । सर्वांनी मला घेरले । संकट विनाकारण ओढवले ॥ ८८ ॥
तूच धाव आता । तूच ठरणार त्राता । तू दिलेला हा दुपेटा । तूच म्या रक्ष आता ॥ ८९ ॥
पालवी न फुटता । लोक मारतील लाथा । खरा शिष्य ठरेल खोटा । तुझ्या नावाला लागेल बट्टा ॥ ९० ॥
त्याला प्रेरणा मिळाली । त्याला हिंमत आली । नामगजराची युक्ती सुचली । तीच तारती झाली ॥ ९१ ॥
लोकांना अट घातली । धून लावता धावेल गुरू माऊली । लाभता कृपासावली । वृक्षास येतील पाने भली ॥ ९२ ॥
गावकरी वदले त्यावर । धून लावल्यावर । जर पालवी न फुटणार । तर आम्ही तुला बडवणार ॥ ९३ ॥
त्याच्या सुरात सूर मिसळला । काही काळ लोटला । सुकलेल्या फांदीला । चीक दिसू लागला ॥ ९४ ॥
जो तो पाहू लागला । आश्चर्याचा धक्का बसला । स्वतःस चिमटे काढु लागला । भानाचाही विसर पडला ॥ ९५ ॥
लोकांस जादूचे खेळ वाटले ।लोकांस नजरबंदीचे खेळ वाटले । प्रथम विश्वास न बसले । खरे बोलणे खोटे वाटले ॥ ९६ ॥
अखेर पितांबर शिष्य पटले । गावकरी माफी मागू लागले । विनम्र भावे वंदन केले । फुलहार गळ्यात घातले ॥ ९७ ॥
पितांबर शहाणा ठरला । गैरसमज दूर झाला । पालवी फुटली डहाळीला । हिरवा रंग पालवीला ॥ ९८ ॥
गावकर्‍यांनी पितांबरास ओळखले । त्याला पालखीतुनी मिरविले । त्याचे शब्द झेलू लागले ॥ ९९ ॥
गुरूकृपेनेच सर्व घडते । अन्यथा विपरीत घडते । उगाच नाही कुणी शब्द झेलत । कृती असावी लागते श्रेष्ठ ॥ १०० ॥
पितांबराच्या शोधात गुरू येतील । वासराच्या शोधात गोमाता येईल । सारे गाव पावन होईल । नव चैतन्य निर्माण होईल ॥ १०१ ॥
त्या झाडास पुढे जी फळे आली । इतरांपेक्षा जास्त आली । गावकर्‍यांनी चूक सावरली । कोंडोली कर्मभूमी ठरली ॥ १०२ ॥
जैसे रक्षिले पितांबरास । तैसेच रक्षो तुम्हास । पाळावे गुरू आज्ञेस । जेणे व्हाल गुरूकृपेस ॥ १०३ ॥
एकेकाची कर्मभूमी निश्चित असते । गुरू गजाननाचे तसेच होते । शेगाव सुटत नव्हते । तेच त्यांच्या नशिबी होते ॥ १०४ ॥
गावकरी सेवा करीत होते । समर्थ एका घरी स्थिर नव्हते । त्यांना भक्त आवडत होते । एकाकडे रहाणे पसंत नव्हते ॥ १०५ ॥
उगाच भक्तांच्या घरच्यांस । नको उपाध्या वाट्यास । सार्‍यांनीच त्यांच्या तैनातीस । पसंत नव्हते त्यांस ॥ १०६ ॥
खर्‍या अर्थी योगीपुरूष होते । नुसत्या देहाने जगत नव्हते । प्रत्येक कृतीतून पटवत होते । उगाचच स्वतःस मिरवत नव्हते ॥ १०७ ॥
वारंवार प्रसंग यायचे । नित्य नविन घडायचे । जशास तसे न वागायचे । हेच सर्वांना पटवायचे ॥ १०८ ॥
सहज कृतीतुन उतरत होते । अंगवळणीच पडले होते । मुद्दाम काहिच करायचे नसते । हेच ते पटवत होते ॥ १०९ ॥
जसा कुणी पाहुणा येता । मुद्दाम चहा चांगला करता । काहितरी न्यूनता । हेच तुम्ही अनुभवता ॥ ११० ॥
मुद्दाम न काही करायचे । सहजपणे जगायचे । ओढून ताणून न वागायचे । हेच त्यांना पटवायचे ॥ १११ ॥
जो सर्वांची मने जिंकणार । त्यांच्या हृदयात वसणार । जो तो त्यांचाच होणार । का न तो योगी होणार ? ॥ ११२ ॥
ऐसी कृती करावी । दुसर्‍याच्या हृदयात ठसावी । सदा विशेष ठरावी । प्रेरणादायक ठरावी ॥ ११३ ॥
समर्थ शिकलेले नव्हते । त्यांना उपजतच होते । पुष्कळसे उपजततेवर असते । जे सारे शिक्षणातून न मिळते ॥ ११४ ॥
योगी सर्व सारखे मानणार । घरदार विशेष नसणार । जो दिशा हेच वस्त्र समजणार । कैसा घरास महत्व देणार ? ॥ ११५ ॥
एके दिवशी सर्व भक्तांस । समर्थे सांगितले त्यांस । कुणाचे घर नको आम्हांस । जाऊ दुसर्‍या जागेस ॥ ११६ ॥
गावात दुफळी माजली फार । जो तो कुरापती काढणार । हे न मला खपणार । मी न आता येथे रहाणार ॥ ११७ ॥
मी निघालो, सोडीन गाव । नको हे शेगाव । शोधीन दुसरा गाव । गर्दीस न मिळे वाव ॥ ११८ ॥
विधात्यास मंजूर नव्हते । प्रारब्ध आडवे होते । कर्मभूमी बदलत नव्हते । तेणे शेगावीच झाले रहाते ॥ ११९ ॥
कुणाची बदली करणे । अथवा ती रद्द करणे । हे अधिकार्‍यांचे पहाणे । त्यानेच अधिकार गाजवणे ॥ १२० ॥
अधिकार्‍यास विनविता । त्याची मर्जी संपादन करता । हेतू साध्य करता । अन्यथा विपरित भोगता ॥ १२१ ॥
ईश्वर जगात कर्ता । पालन कर्ता नि त्राता । सर्वोच्च अधिकार हाता । सूत्रे चालन कर्ता ॥ १२२ ॥
भक्तांच्या प्रार्थना । ईश्वरास नाना । समर्थांनी तेथून हलण्या । नव्हते पसंत कुणा ॥ १२३ ॥
प्रेरणा दिली भक्तांस । भक्त वदले समर्थांस । माफी असावी कृत्यांस । सोडू नका शेगावास ॥ १२४ ॥
तुम्ही सांगाल ते ते कृतीतुनी । नाही दुखवणार कुणी । विनवणी केली भक्तांनी । जाऊ नये शेगावातुनी ॥ १२५ ॥
जेव्हा कुणी तयार नसते । गळीच उतरवावे लागते । शेगावकर हुशार होते । पक्के मुरलेले होते ॥ १२६ ॥
समर्थांस पटवणे सोपे नव्हते । शेगावकर योग्य होते । सरस पट्टीतले होते । सोडण्यास तयार नव्हते ॥ १२७ ॥
समर्थांची कृपादृष्टी । तीच होती आधारमूर्ती । उपाधी वाटत नव्हती । परी सोबतच होती ॥ १२८ ॥
अंधारातून प्रकाशाकडे । नेण्यास समर्थ बापडे । खरा आधार सापडे । कोण त्यांना सोडे ? ॥ १२९ ॥
वागणूक अशी असावी । दुसर्‍यांस सोबत ठरावी । निःस्पृहतेची जोड हवी । शुद्ध चित्ताची साथ हवी ॥ १३० ॥
शब्द मोडवेना भक्तांचा । केवढा कनवाळू मनाचा । शोधून न सापडायचा । ऋणानुबंध शेगावचा ॥ १३१ ॥
भाग्य शेगावकरांचे । समर्थे ऐकावे त्यांचे । बदलावे विचार स्वतःचे । भक्तांचे होऊन रहावयाचे ॥ १३२ ॥
अशी जागा घ्यावी । कुणाची मालकी नसावी । मला मिळता ग्वाही । राहीन मी शेगावी ॥ १३३ ॥
समर्थांचे चमत्कारिक । नाही व्यवहारिक । हवी असता त्यांची जवळिक । पाळावा लागतो त्यांचा शब्द एकेक ॥ १३४ ॥
भक्तांस पडला प्रश्र्न । केले त्यांचे स्मरण । समर्थे जाणले मन । टाळला बिकट क्षण ॥ १३५ ॥
समर्थ वदले भक्तांस । सरकार देईल जागेस । मागावे जागेस । येईल त्यात यश ॥ १३६ ॥
जागा नाही सरकारची । मालकी सच्चिदानंदाची । छाती न त्याची व्हावयाची । जागा नाकारण्याची ॥ १३७ ॥
तेव्हा सत्ता परकीयांची । इंग्रज सरकारची । वेळ होती भारताची । त्यांच्या गुलामगिरीची ॥ १३८ ॥
भारतवासी गुलाम होते । इंग्रजांस भुलवणे सोपे नव्हते । समर्थांचे सहाय्य होते । तेणे सर्व जुळत होते ॥ १३९ ॥
गुरू आज्ञा प्रमाण मानले । जागेसाठी अर्ज केले । अधिकार्‍यांनी वाचले । सर्व विचारात पडले ॥ १४० ॥
त्यावर बैठकी झाल्या । सल्ला मसलती झाल्या । काही अटी घातल्या । अर्ज मंजूर झाला ॥ १४१ ॥
लेखी जवाब मिळाला । सरकारचा होकार कळला । आनंदास पारावार न राहिला । समर्थांचा शब्द खरा ठरला ॥ १४२ ॥
मठासाठी “करी” साहेबांनी । जागा दिली तत्क्षणी । एक एकर जागा देऊनी । तपशील दिला धाडुनी ॥ १४३ ॥
समर्थांची जैशी कृपा शेगावास । तैशी असुद्या बडोद्यास । भव्य दिव्य वास्तू बडोद्यास । हीच ईच्छा गणामहाराजास ॥ १४४ ॥
जागा मिळाल्यावर जे घडते । ते पुढिल अध्यायी येते । प्रसंग वाचावेत शांत चित्ते । होईल प्रसन्न मन त्याते ॥ १४५ ॥
नका आचरू दंभाचारास । नको वैभवाच्या प्रदर्शनास । ऐसे आळवावे गजाननास । पालवी फुटेल कल्पवृक्षास ॥ १४६ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य द्वादशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
